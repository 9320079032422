.appDarkShow .projectLegend,
.projectLegend {
  color: #00f !important;
}
.project {
  min-height: 100px;
  width: calc(50% - 20px);
  margin: 0 10px 10px;
  display: inline-block;
}
.projectLegend {
  border-top-left-radius: 18px;
  padding: 10px;
  margin-bottom: 5px;
  background: #cdcdcd;
  background: linear-gradient(90deg, #d5d5d5 0, #e4e4e4 35%, #fff 100%);
  font-weight: bolder;
  font-size: 12px;
  background-color: silver;
}
.skeletonsProjectsBox {
  width: calc(50% - 20px) !important;
  margin: 10px !important;
  float: left !important;
  height: 250px;
}
.skeletonsProjects {
  height: 100%;
  border-top-left-radius: 18px !important;
}
.appDarkShow .skeletonsProjectsBox {
  opacity: 0.1;
}
.imgHoverBg {
  position: relative;
  overflow: hidden;
  height: 250px;
}
.imgHoverMax {
  position: absolute;
  top: 0;
  right: 10px;
  width: 100px;
  transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
  -moz-transition: transform 0.2s;
  -ms-transition: transform 0.2s;
  -o-transition: transform 0.2s;
}
.imgHoverMax:hover {
  transform: scale(1.03);
  -webkit-transform: scale(1.03);
  -moz-transform: scale(1.03);
  -ms-transform: scale(1.03);
  -o-transform: scale(1.03);
}
.imgHover {
  width: 100%;
  min-height: 100%;
}
@media (max-width: 1250px) {
  .skeletonsProjectsBox {
    width: calc(100% - 20px) !important;
  }
  .project {
    width: calc(100% - 20px);
  }
}
@media (max-width: 700px) {
  .imgHoverBg {
    height: 90px;
  }
  .skeletonsProjectsBox {
    height: 140px;
  }
  .imgHoverMax {
    width: 60px;
  }
}
@media (max-width: 500px) {
  .imgHoverBg {
    height: 90px;
  }
  .skeletonsProjectsBox {
    height: 130px;
  }
}
@media (max-width: 350px) {
  .imgHoverMax {
    width: 40px;
  }
}
